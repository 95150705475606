import React from "react";
import MainLogo from "assets/main-logo.svg";
import { HomeOutlined } from "@ant-design/icons";

const SideNav = () => {
  return (
    <div className="flex flex-col gap-16 h-full">
      <div className="h-[4.5rem] flex items-center justify-center">
        <img src={MainLogo} alt="logo" className="h-3/4" />
      </div>
      <div className="flex flex-col text-orange gap-4 cursor-pointer	">
        <div className="flex flex-col items-center ">
          <div>
            <HomeOutlined className="text-2xl" />
          </div>
          <div className="text-base">Home</div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
