import React from "react";

const Header = () => {
  return (
    <header
      className="h-[4.5rem]  "
      style={{ boxShadow: " 0 4px 2px -4px grey" }}
    ></header>
  );
};

export default Header;
