// Filename: updatedFile.ts

import { ToastOptions } from "react-toastify";

export const StepItems: { title: string; description: string }[] = [
  {
    title: "Step 1",
    description: "Consent Collection",
  },
  {
    title: "Step 2",
    description: "Aadhaar Authentication",
  },
  {
    title: "Step 3",
    description: "ABHA Address Creation",
  },
  {
    title: "Step 4",
    description: "Download ABHA Card",
  },
];

export const ToastConfiguration: ToastOptions = {
  position: "bottom-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
