import { Modal } from "antd";
import React from "react";
import { WarningFilled } from "@ant-design/icons";

const MessagePopup = ({
  toggleModal,
  shouldDisplayMessageModal,
  Message,
  className,
  title,
  state,
}: any) => {
  return (
    <Modal
      wrapClassName="common-modal-wrapper"
      title={title}
      open={shouldDisplayMessageModal}
      //   onCancel={handleClose}
      footer={null}
      className={className}
      onCancel={toggleModal}
    >
      <div className="flex flex-col items-center gap-6">
        <div
          className="flex flex-row items-center justify-center bg-red"
          style={{
            borderRadius: "50%",

            width: "4rem",
            height: "4rem",
          }}
        >
          <WarningFilled style={{ fontSize: "2rem", color: "white" }} />
        </div>
        <div className="text-xl text-center">
          {Message?.length > 0
            ? Message
            : "ABDM servers are currently experiencing high demand, please try again in sometime."}
        </div>
        <div className="text-sm	font-normal">
          <button
            type="button"
            className=" text-white bg-blue focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 "
            onClick={toggleModal}
          >
            Retry
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MessagePopup;
