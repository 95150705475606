import axios from "axios";
import {
  LoaderContext,
  LoaderContextType,
} from "components/loader/loader-middleware";
import { ToastConfiguration } from "constants/abha-flow-constants";
import { M1_FLOW_URLS } from "constants/url-constants";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

const useResendAadharOtpTimer = ({
  current,
  header,
  txnId,
  isMobileLinked,
}: any) => {
  const loaderContext = useContext(LoaderContext);

  const { setLoading } = loaderContext || ({} as LoaderContextType);
  // states used to resend OTP
  const [aadharOtpTimer, setAadharOtpTimer] = useState(60);

  const handleAadharOtpClickResendClick = () => {
    setLoading(true);
    axios
      .post(
        `${M1_FLOW_URLS.resendAadharOtp}`,
        { txnId },
        {
          headers: header,
        }
      )
      .then(() => {
        toast.success("OTP resent successful", ToastConfiguration);

        setAadharOtpTimer(60);
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.details?.[0]?.message || "Something went wrong",
          ToastConfiguration
        );
        setAadharOtpTimer(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let intervalId: any;
    if (current === 1 && isMobileLinked === true) {
      if (aadharOtpTimer > 0) {
        intervalId = setInterval(() => {
          console.log(aadharOtpTimer);
          setAadharOtpTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        console.log("entered else");
        clearInterval(intervalId);
      }
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => clearInterval(intervalId);
  }, [aadharOtpTimer, current, isMobileLinked]);
  return {
    aadharOtpTimer,

    handleAadharOtpClickResendClick,
  };
};

export default useResendAadharOtpTimer;
