import React, { useRef, useState } from "react";
import { Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const AadharInputBox = (props: any) => {
  const inputRefs: React.MutableRefObject<any | null>[] = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [cardNumber, setCardNumber] = useState(["", "", ""]);
  const [showDetails, setShowDetails] = useState(false);
  const [inputType, setInputType] = useState("password");

  const handleInputChange = (value: any, index: any) => {
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    const newCardNumber = [...cardNumber];
    newCardNumber[index] = numericValue;

    // Automatically focus on the next input box
    if (index < 2 && numericValue.length === 4) {
      inputRefs[index + 1].current.focus();
    }

    setCardNumber(newCardNumber);
    props.updateAadharNumber(newCardNumber.join(""));
  };

  const handleKeyDown = (index: any, e: any) => {
    // Handle Backspace key
    if (e.key === "Backspace" && index > 0 && cardNumber[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
    setInputType(showDetails ? "password" : "text");

    // Set the input type for the third box
    if (inputRefs[2].current) {
      inputRefs[2].current.type = showDetails ? "password" : "text";
    }
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="flex flex-row gap-8 	">
        {cardNumber.map((value, index) => (
          <Input
            key={index}
            placeholder="XXXX"
            type={inputType}
            id={`input-${index}`}
            className="w-36 h-12 text-center text-xl font-semibold border-2 border-input-border-color"
            maxLength={4}
            value={value}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={inputRefs[index]}
            tabIndex={index + 1} // Add tabIndex attribute
          />
        ))}
      </div>
      <div>
        <Button
          type="text"
          icon={
            !showDetails ? (
              <EyeInvisibleOutlined style={{ fontSize: "1.5rem" }} />
            ) : (
              <EyeOutlined style={{ fontSize: "1.5rem" }} />
            )
          }
          onClick={handleToggleDetails}
        ></Button>
      </div>
    </div>
  );
};

export default AadharInputBox;
