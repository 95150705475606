import React, { useContext } from "react";
import { LoaderContext, LoaderContextType } from "./loader-middleware";
import "./loader.css";
import { Spin } from "antd";

const Loader = ({ children }: any) => {
  // const loading = useContext(LoaderContext);

  const loaderContext = useContext(LoaderContext);

  const { loading } = loaderContext || ({} as LoaderContextType);

  return (
    <>
      {/* Put your loader here */}

      {loading && (
        <div className="loader-container">
          <div className="loader-icon">
            <Spin size="large" />
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default Loader;
