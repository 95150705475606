import React, { useRef, useState } from "react";
import { Input } from "antd";

const OtpInputBox = (props: any) => {
  const inputRefs: React.MutableRefObject<any | null>[] = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [inputNumber, setInputNumber] = useState(["", "", "", "", "", ""]);

  const handleInputChange = (value: any, index: any) => {
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    const newinputNumber = [...inputNumber];
    newinputNumber[index] = numericValue;

    // Automatically focus on the next input box
    if (index < 5 && numericValue.length === 1) {
      inputRefs[index + 1].current.focus();
    }

    setInputNumber(newinputNumber);
    props.updateOTP(newinputNumber.join(""));
  };

  const handleKeyDown = (index: any, e: any) => {
    // Handle Backspace key
    if (e.key === "Backspace" && index > 0 && inputNumber[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="flex flex-row gap-5 	">
        {inputNumber.map((value, index) => (
          <Input
            key={index}
            type={"text"}
            id={`input-${index}`}
            className="w-12 h-12 text-center text-xl font-semibold border-input-border-color"
            maxLength={1}
            value={value}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={inputRefs[index]}
            tabIndex={index + 1} // Add tabIndex attribute
          />
        ))}
      </div>
    </div>
  );
};

export default OtpInputBox;
