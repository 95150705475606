import { Routes, Route } from "react-router-dom";
import Home from "components/home";
import "./custom-theme.less"; // Import the compiled CSS file
import Header from "core/header/header";
import SideNav from "core/side-nav/side-nav";
import { ConfigProvider } from "antd";
import { LoaderMiddleware } from "./components/loader/loader-middleware";
import Loader from "./components/loader/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AbhaTerms from "components/abha-terms";
import { ReactNode } from "react";

interface ComponentWithLoaderProps {
  children: ReactNode;
}

export const ComponentWithLoader: React.FC<ComponentWithLoaderProps> = ({
  children,
}) => {
  return (
    <LoaderMiddleware>
      <Loader>{children}</Loader>
    </LoaderMiddleware>
  );
};

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#00b96b",
        },
      }}
    >
      <ToastContainer />
      <div className="flex flex-row gap-0 w-full h-[100vh]">
        <section className="w-20 bg-blue ">
          <SideNav />
        </section>
        <div className="flex flex-col w-full">
          <Header />
          <section className="w-full">
            <Routes>
              <Route
                path="/"
                element={
                  <ComponentWithLoader>
                    <Home />
                  </ComponentWithLoader>
                }
              />
              <Route
                path="/abha-terms"
                element={
                  <ComponentWithLoader>
                    <AbhaTerms />
                  </ComponentWithLoader>
                }
              />
            </Routes>
          </section>
        </div>
        <footer></footer>
      </div>
    </ConfigProvider>
  );
}

export default App;
