import React from "react";
import CreateAbhaFlow from "./create-abha-flow/create-abha-flow";

function Home() {
  return (
    <div>
      <CreateAbhaFlow />
    </div>
  );
}

export default Home;
