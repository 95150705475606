export const ROOJH_CONNECT_API_URL =
  process.env.REACT_APP_ROOJH_CONNECT_API_URL;

export const M1_FLOW_URLS = {
  generateAadharOTP: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/generateOtp`,
  verifyAadharOTP: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/verifyOTP`,
  checkAndGenerateMobileOTP: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/checkAndGenerateMobileOTP`,
  verifyMobileOTP: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/verifyMobileOTP`,
  createHealthIdWithPreVerified: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/createHealthIdWithPreVerified`,
  getUserAbhaCard: `${ROOJH_CONNECT_API_URL}api/v1/account/user-abha-card`,

  resendAadharOtp: `${ROOJH_CONNECT_API_URL}api/v1/registration/aadhaar/resendAadhaarOtp`,
};

export const ROOJH_CONNECT_API_KEY =
  process.env.REACT_APP_ROOJH_CONNECT_API_KEY;

export const ROOJH_CONNECT_HEADERS = {
  Authorization: `Basic ${ROOJH_CONNECT_API_KEY}`,
};

export const GET_CONNECT_HEADERS = (paramToken: any) => {
  if (paramToken) {
    return {
      Authorization: `Basic ${paramToken}`,
    };
  } else {
    return {
      Authorization: `Basic ${ROOJH_CONNECT_API_KEY}`,
    };
  }
};
