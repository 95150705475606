import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Input, Steps, Tag } from "antd";
import AbhaPlaceholder from "../../assets/abha-placeholder.png";
import SampleAbhaCard from "../../assets/sample-abha-card.svg";
import AadharInputBox from "../aadhar-input-box/AadharInputbox";
import OtpInputBox from "../otp-input-box/OtpInputBox";
import { StepItems, ToastConfiguration } from "constants/abha-flow-constants";
import MessagePopup from "components/message-popup/message-popup";
import { toast } from "react-toastify";
import axios from "axios";
import { M1_FLOW_URLS, GET_CONNECT_HEADERS } from "constants/url-constants";
import {
  LoaderContext,
  LoaderContextType,
} from "components/loader/loader-middleware";
import { useSearchParams } from "react-router-dom";

import "./create-abha-flow.css";
import useResendAadharOtpTimer from "hooks/use-resend-aadhar-otp-timer";
import useResendMobileOtpTimer from "hooks/use-resend-mobile-otp-timer";

const CreateAbhaFlow = () => {
  const [searchParams] = useSearchParams();
  const loaderContext = useContext(LoaderContext);

  const { setLoading } = loaderContext || ({} as LoaderContextType);

  const [current, setCurrent] = useState<number>(0);
  const [params, setParams] = useState<any>({});

  useEffect(() => {
    const fetchedParams = Object.fromEntries(searchParams);
    setParams(fetchedParams);
  }, [searchParams]);

  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const toggleModal = () => {
    setIsMessagePopupOpen(!isMessagePopupOpen);
    setPopupMessage("");
  };

  const [flowState, setFlowState] = useState<any>({
    aadharNumber: "",
    transactionID: "",
    aadharOtp: "",
    mobileNumber: "",
    mobileOtp: "",
    abhaAddress: "",
    isTermsChecked: false,
    isWhatsappChecked: true,
    isMobileLinked: true,
    transactionID2: "",
    suggestions: [],
    userDetails: {},
    userAbhaCard: {},
    token: "",
    finalDetails: {},
  });

  const {
    aadharOtpTimer,

    handleAadharOtpClickResendClick,
  } = useResendAadharOtpTimer({
    current,
    header: GET_CONNECT_HEADERS(params?.token),
    txnId: flowState?.transactionID,
    isMobileLinked: flowState?.isMobileLinked,
  });

  const { mobileOtpTimer, setMobileOtpTimer } = useResendMobileOtpTimer({
    current,
    isMobileLinked: flowState?.isMobileLinked,
    header: GET_CONNECT_HEADERS(params?.token),
    txnId: flowState?.transactionID,
  });

  const onChangeCheckBox = (value: any, type: any) => {
    if (type === "whatsapp") {
      setFlowState({ ...flowState, isWhatsappChecked: value });
      return;
    } else {
      setFlowState({ ...flowState, isTermsChecked: value });
      return;
    }
  };

  const onChangeAadharNumber = (newNumber: any) => {
    setFlowState({ ...flowState, aadharNumber: newNumber });
  };

  const onChangeAadharOtp = (newOTP: any) => {
    setFlowState({ ...flowState, aadharOtp: newOTP });
  };

  const onChangeMobileNumber = (mobile: any) => {
    // Filter out non-numeric characters
    const filteredNumber = mobile.replace(/\D/g, "");
    setFlowState({ ...flowState, mobileNumber: filteredNumber });
  };

  const getMinutesFormat = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const onChangeMobileOtp = (newOTP: any) => {
    setFlowState({ ...flowState, mobileOtp: newOTP });
  };

  const onChangeAbhaAddress = (address: any) => {
    if (address?.length < 18) {
      setFlowState({ ...flowState, abhaAddress: address });
    }
  };

  const onSubmitStep1 = () => {
    // check if the consent is checked or not
    if (flowState?.isTermsChecked === false) {
      toast.error("Please accept terms and conditions", ToastConfiguration);
      return;
    }
    // check if the aadhar number size is 12 else return error
    if (
      !flowState?.aadharNumber ||
      flowState?.aadharNumber?.length < 12 ||
      flowState?.aadharNumber === ""
    ) {
      toast.error("Please enter your Aadhaar Number", ToastConfiguration);
      return;
    } else {
      // if length is good, please make API call and update error and response
      setLoading(true);
      const generateAadharOTPBody = {
        aadhaar: flowState?.aadharNumber,
      };

      axios
        .post(`${M1_FLOW_URLS.generateAadharOTP}`, generateAadharOTPBody, {
          headers: GET_CONNECT_HEADERS(params?.token),
        })
        .then((response: any) => {
          if (response?.data?.txnId) {
            setFlowState({
              ...flowState,
              transactionID: response.data.txnId,
            });
            setCurrent((prev) => {
              return prev + 1;
            });
          }
        })
        .catch((e: any) => {
          console.log(e);
          setIsMessagePopupOpen(true);
          setPopupMessage(e?.response?.data?.details[0]?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onSubmitStep2 = () => {
    //validation to check user types both Aadhar OTP and mobile Number together
    if (
      !flowState?.mobileNumber ||
      flowState?.mobileNumber?.length < 10 ||
      flowState?.mobileNumber === ""
    ) {
      toast.error("Please Input your mobile number", ToastConfiguration);
    }

    if (
      !flowState?.aadharOtp ||
      flowState?.aadharOtp?.length < 6 ||
      flowState?.aadharOtp === ""
    ) {
      toast.error("Please Input your Aadhaar OTP", ToastConfiguration);
    }

    // submit aadhar OTP

    const VerifyAadharOTPBody = {
      otp: flowState?.aadharOtp,
      txnId: flowState?.transactionID,
    };
    setLoading(true);
    axios
      .post(`${M1_FLOW_URLS.verifyAadharOTP}`, VerifyAadharOTPBody, {
        headers: GET_CONNECT_HEADERS(params?.token),
      })
      .then((response: any) => {
        setMobileOtpTimer(60);
        console.log(response);
        // make check and generate api call
        checkAndGenerateApiCall();
      })
      .catch((e: any) => {
        setMobileOtpTimer(0);
        setLoading(false);
        setIsMessagePopupOpen(true);
        setPopupMessage(e.response.data.details[0].message);
      });
  };

  const checkAndGenerateApiCall = (isResend = false) => {
    setLoading(true);

    const bodyToSend = {
      mobile: flowState?.mobileNumber,
      txnId: flowState?.transactionID,
    };
    axios
      .post(`${M1_FLOW_URLS.checkAndGenerateMobileOTP}`, bodyToSend, {
        headers: GET_CONNECT_HEADERS(params?.token),
      })
      .then((response: any) => {
        console.log(response);
        if (isResend) {
          setMobileOtpTimer(60);
          toast.success("OTP resent successful", ToastConfiguration);
        }
        setFlowState({
          ...flowState,
          isMobileLinked: response?.data?.mobileLinked,
          transactionID2: response?.data?.txnId,
        });
        if (response?.data?.mobileLinked === true) {
          createHealthIdWithPreVerified();
          setCurrent((prev) => {
            return prev + 1;
          });
          // add phr suggestions here
        }
      })
      .catch((e: any) => {
        if (isResend) {
          setMobileOtpTimer(0);
          toast.error(
            e?.response?.data?.details?.[0]?.message || "Something went wrong",
            ToastConfiguration
          );
        }
        setIsMessagePopupOpen(true);
        setPopupMessage(e?.response?.data?.details[0]?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyMobileOtp = () => {
    //validation to check user enters proper Mobile OTP
    if (flowState?.mobileOtp?.length < 6 || flowState?.mobileOtp === "") {
      toast.error("Please Input your mobile OTP", ToastConfiguration);
      return;
    }
    setLoading(true);
    const bodyVerifyOTP = {
      otp: flowState?.mobileOtp,
      txnId: flowState?.transactionID2,
    };
    axios
      .post(`${M1_FLOW_URLS.verifyMobileOTP}`, bodyVerifyOTP, {
        headers: GET_CONNECT_HEADERS(params?.token),
      })
      .then((response: any) => {
        createHealthIdWithPreVerified();

        if (response?.data?.txnId) {
          setCurrent((prev) => {
            return prev + 1;
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
        setIsMessagePopupOpen(true);
        setPopupMessage(e?.response?.data?.details[0]?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onNextClicked = () => {
    // write handlers for each step here

    //Step1 handler
    if (current === 0) {
      onSubmitStep1();
      return;
    }
    if (current === 1 && flowState?.isMobileLinked === true) {
      onSubmitStep2();
      return;
    }
    if (current === 1 && flowState?.isMobileLinked === false) {
      verifyMobileOtp();
      return;
    }
    if (current === 2) {
      onSubmitStep3();
      return;
    }
  };

  const onSubmitStep3 = () => {
    //No Validations

    setLoading(true);

    const finalToken = GET_CONNECT_HEADERS(params?.token);

    const HeaderWithToken = {
      ...finalToken,
      "X-Token": `Bearer ${flowState?.token}`,
    };

    axios
      .get(`${M1_FLOW_URLS.getUserAbhaCard}`, {
        headers: HeaderWithToken,
        params: {
          phrAddress: flowState?.abhaAddress,
          transactionId: flowState?.userDetails?.transactionId,
          marsId: params?.marsId,
        },
      })
      .then((response: any) => {
        setFlowState({ ...flowState, finalDetails: response?.data });
        if (response?.data) {
          setCurrent((prev) => {
            return prev + 1;
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
        setIsMessagePopupOpen(true);
        setPopupMessage(e?.response?.data?.details[0]?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createHealthIdWithPreVerified = () => {
    //No validations

    setLoading(true);

    const bodyCreateHealthIdWithPreVerified = {
      txnId:
        flowState?.transactionID2 === ""
          ? flowState?.transactionID
          : flowState?.transactionID2,
    };
    axios
      .post(
        `${M1_FLOW_URLS.createHealthIdWithPreVerified}`,
        bodyCreateHealthIdWithPreVerified,
        {
          headers: GET_CONNECT_HEADERS(params?.token),
        }
      )
      .then((response: any) => {
        if (response?.data?.suggestion?.length > 0) {
          setFlowState({
            ...flowState,
            suggestions: response?.data?.suggestion,
            userDetails: response?.data?.userDetails,
            token: response?.data?.token,
          });
        }
      })
      .catch((e: any) => {
        console.log(e);
        setIsMessagePopupOpen(true);
        setPopupMessage(e?.response?.data?.details[0]?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSuggestionClick = (item: any) => {
    setFlowState({ ...flowState, abhaAddress: item });
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:image/png;base64,${flowState?.finalDetails?.userCard}`;
    link.download = `${flowState?.finalDetails?.userDetails?.healthId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (current === 3 && params?.redirecturl) {
      const finalDetails = flowState?.finalDetails?.userDetails;

      // const getSymbol = () => {
      //   if (params?.redirecturl?.includes("?")) {
      //     return "&";
      //   }
      //   if (params?.redirecturl?.includes("&")) {
      //     return "?";
      //   } else {
      //     return "";
      //   }
      // };

      // Set the URL to redirect to
      let redirectUrl = `https://${params?.redirecturl}&`;

      Object.keys(params).forEach((key) => {
        redirectUrl = redirectUrl + `${key}=${params[key]}&`;
      });

      Object.keys(finalDetails).forEach((key) => {
        if (
          key === "profilePhoto" ||
          key === "kycPhoto" ||
          key === "tags" ||
          key === "authMethods" ||
          key === "phrAddress"
        ) {
          return;
        }
        if (finalDetails[key]) {
          redirectUrl = redirectUrl + `${key}=${finalDetails[key]}&`;
        }
      });

      // Set the time delay in milliseconds (e.g., 5000 for 5 seconds)
      const delay = 5000;

      // Set up the timer to redirect after the specified delay
      const timerId = setTimeout(() => {
        // Redirect the user to the specified URL
        window.location.replace(redirectUrl);
      }, delay);

      // Clear the timer if the component is unmounted before the timeout
      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <MessagePopup
        shouldDisplayMessageModal={isMessagePopupOpen}
        // title={"OOOPS!!!"}
        Message={popupMessage}
        toggleModal={toggleModal}
      />
      <div className="flex flex-col gap-4 pt-10 items-center	">
        <div className=" w-full xl:w-3/5 lg:w-4/5  ">
          <Steps
            current={current}
            labelPlacement="vertical"
            // disabled={true}
            items={StepItems}
            status={current === 3 ? "finish" : undefined}
          />
        </div>
        {/* step 0 */}
        <div className="flex flex-col lg:flex-row items-center justify-center mt-12 w-full px-0 lg:px-8 xl:px-0  ">
          <div className=" flex flex-col gap-4 w-full xl:w-1/2 items-center lg:items-start">
            {current !== 3 && (
              <div className="text-2xl font-normal">
                Create ABHA Number Using Aadhaar
              </div>
            )}
            {current === 0 && (
              <>
                <div className="text-base font-normal mt-4">
                  Enter Your Aadhaar Number
                  <span style={{ color: "red" }}>*</span>
                </div>
                {/* <div className="text-base font-normal ">Aadhar Input field</div> */}
                <AadharInputBox updateAadharNumber={onChangeAadharNumber} />
                <div className="text-sm	font-normal text-grey mt-4">
                  <Checkbox
                    onChange={(e) =>
                      onChangeCheckBox(e.target.checked, "whatsapp")
                    }
                    checked={flowState?.isWhatsappChecked}
                    className="text-grey"
                  >
                    Send ABHA Card on WhatsApp
                  </Checkbox>
                </div>
                <div className="text-sm	font-normal ">
                  <Checkbox
                    checked={flowState?.isTermsChecked}
                    onChange={(e) =>
                      onChangeCheckBox(e.target.checked, "terms")
                    }
                    className="text-grey "
                  >
                    I agree to give necessary permission to Roojh to set up my
                    account.
                  </Checkbox>
                  <a
                    href="./abha-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </div>
              </>
            )}
            {/* step 1 */}
            {current === 1 && (
              <>
                {flowState?.isMobileLinked ? (
                  <>
                    {" "}
                    <div className="flex flex-col gap-4">
                      <div className="text-sm font-normal text-grey	">
                        Enter OTP recieved on phone number linked with your
                        Aadhaar
                      </div>
                      <div className="font-normal	text-base">
                        Aadhaar OTP Verification
                      </div>
                      <div>
                        <OtpInputBox updateOTP={onChangeAadharOtp} />
                      </div>
                      <div className="flex flex-row gap-8 justify-between">
                        <div className="text-sm	 text-grey">
                          Didn’t receive OTP?
                        </div>
                        {
                          <div
                            onClick={
                              aadharOtpTimer < 1
                                ? handleAadharOtpClickResendClick
                                : undefined
                            }
                            className={`-ml-8 text-sm ${
                              aadharOtpTimer < 1
                                ? "hover:underline text-blue hover:cursor-pointer"
                                : "text-grey cursor-not-allowed"
                            }`}
                          >
                            Resend OTP
                          </div>
                        }
                        <div className="text-sm	 text-grey">
                          {getMinutesFormat(aadharOtpTimer)} remaining
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 mt-4">
                        <div className="font-normal	text-base">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </div>
                        <div>
                          <Input
                            type={"text"}
                            className=" h-12 text-center text-xl font-semibold border-input-border-color w-96"
                            maxLength={10}
                            value={flowState?.mobileNumber}
                            onChange={(e) =>
                              onChangeMobileNumber(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-2 mt-2">
                      <div className="font-normal	text-base">
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <Input
                          type={"text"}
                          className=" h-12 text-center text-xl font-semibold border-input-border-color w-96 mb-8"
                          maxLength={10}
                          value={flowState?.mobileNumber}
                          disabled={true}
                          // onChange={(e) => handleInputChange(e.target.value, index)}
                          // onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      </div>

                      <div className="font-normal	text-base">
                        Mobile OTP Verification
                      </div>
                      <div className="text-sm font-normal text-grey my-2	">
                        OTP sent to the above mobile number
                      </div>
                      <div>
                        <OtpInputBox updateOTP={onChangeMobileOtp} />
                      </div>

                      <div>
                        <div>
                          {" "}
                          <div className="flex flex-row gap-8 justify-between">
                            <div className="text-sm	 text-grey">
                              Didn’t receive OTP?
                            </div>
                            {
                              <div
                                onClick={() =>
                                  mobileOtpTimer < 1
                                    ? checkAndGenerateApiCall(true)
                                    : undefined
                                }
                                className={`-ml-8 text-sm ${
                                  mobileOtpTimer < 1
                                    ? "hover:underline text-blue hover:cursor-pointer"
                                    : "text-grey cursor-not-allowed"
                                }`}
                              >
                                Resend OTP
                              </div>
                            }
                            <div className="text-sm	 text-grey">
                              {getMinutesFormat(mobileOtpTimer)} remaining
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {current === 3 && (
              <div className="flex flex-col gap-4 items-center">
                <img
                  src={`data:image/png;base64,${flowState?.finalDetails?.userCard}`}
                  alt="abha card"
                  className=""
                />
                <div className="text-base font-semibold text-center">
                  Powered by <span className="text-orange">Roojh</span>
                </div>
                <button
                  type="button"
                  className="mt-2 text-white bg-blue focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 "
                  onClick={handleDownload}
                >
                  Download
                </button>
              </div>
            )}
            {current === 2 && (
              <div className="flex flex-col gap-4">
                {false ? (
                  <div className="flex flex-col gap-4">
                    <img src={SampleAbhaCard} alt="abha card" />
                    <div className="text-base font-semibold text-center">
                      Powered by <span className="text-orange">Roojh</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col gap-2 mt-4">
                      <div className="font-normal	text-base">
                        ABHA Address<span style={{ color: "red" }}>*</span>
                      </div>
                      <div>
                        <Input
                          type={"text"}
                          className=" h-12 text-center text-xl font-semibold border-input-border-color w-96"
                          suffix={
                            <div className="text-base text-grey">@ABHA</div>
                          }
                          value={flowState?.abhaAddress}
                          onChange={(e) => onChangeAbhaAddress(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 cursor-pointer">
                      <div className="font-bold text-sm">
                        {flowState?.suggestions?.length > 0 && (
                          <>Suggestions:</>
                        )}{" "}
                      </div>
                      <div className="grid grid-cols-3 grid-rows-auto gap-2">
                        {flowState?.suggestions?.length > 0 &&
                          flowState?.suggestions?.map((item: any) => (
                            <Tag
                              color="default"
                              className="text-black font-semibold bg-white  border-black text-center overflow-x-hidden overflow-y-hidden"
                              key={item}
                              onClick={() => handleSuggestionClick(item)}
                            >
                              {item}
                            </Tag>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {current !== 3 && (
              <div className="text-sm	font-normal">
                <button
                  type="button"
                  className="mt-8 text-white bg-blue focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-8 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 "
                  onClick={onNextClicked}
                >
                  Next
                </button>
              </div>
            )}
          </div>

          {current !== 3 && (
            <div className="mt-16 xl:mt-0">
              <img
                src={AbhaPlaceholder}
                alt="Abha sample placeholder card"
                className="h-[50vh] lg:w-[45rem] xl:w-[30rem]"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateAbhaFlow;
