import { useState, useEffect } from "react";

const useResendMobileOtpTimer = ({ current, isMobileLinked }: any) => {
  // states used to resend OTP
  const [mobileOtpTimer, setMobileOtpTimer] = useState(60);

  useEffect(() => {
    let intervalId: any;
    if (current === 1 && isMobileLinked === false) {
      if (mobileOtpTimer > 0) {
        intervalId = setInterval(() => {
          setMobileOtpTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        clearInterval(intervalId);
      }
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => clearInterval(intervalId);
  }, [mobileOtpTimer, current, isMobileLinked]);
  return {
    mobileOtpTimer,
    setMobileOtpTimer,
  };
};

export default useResendMobileOtpTimer;
