import React, { useState } from "react";

export const LoaderContext = React.createContext<LoaderContextType | undefined>(
  undefined
);

export interface LoaderContextType {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const LoaderMiddleware = ({ children }: any) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};
